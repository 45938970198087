<template>
  <div class="history-content">
    <div class="item" v-for="(it,key) in list" :key="key">
      <p class="plate">{{it.plate}}</p>
      <p class="location"><img src="@/assets/location.png" alt="" srcset="">停车场：{{it.parkingName}}</p>
      <p class="location"><img src="@/assets/location.png" alt="" srcset="">车位号：{{it.seatNum}}</p>
      <p class="location"><img src="@/assets/time.png" alt="" srcset="">停车时长：{{it|times}}</p>
      <p class="time blue">支付时间：{{it.payTime}}</p>
      <p class="time">进场时间：{{it.enterTime}}</p>
      <p class="time red">出场时间：{{it.leaveTime}}</p>
      <div class="line"></div>
      <div class="price">
        <p>￥{{it.payMoney.toFixed(2)}}</p>
        <p @click="imagePreview(it)">查看进出场照片</p>
      </div>
    </div>
    <van-empty description="暂无数据" v-if="list.length==0"/>
  </div>
</template>

<script>
import { ImagePreview } from "vant";
export default {
  name: "payinfo",
  data() {
    return {
      payType: "",
      list: [],
    };
  },
  filters: {
    times: function (value) {
      if (value.enterTime) {
        let leaveTime = value.leaveTime || new Date().getTime();
        let times =
          new Date(leaveTime).getTime() - new Date(value.enterTime).getTime();
        let minute = (times / 60000).toFixed(0);
        if (minute < 60) {
          return `${minute}分钟`;
        } else {
          let hours = Math.floor(times / 3600000);
          minute = minute - 60 * hours;
          return `${hours}小时${minute}分钟`;
        }
      } else {
        return "--";
      }
    },
  },
  created() {
    let { id, code } = this.$route.query;
    if (id) {
      var ua = window.navigator.userAgent.toLowerCase();
      if (ua.indexOf("micromessenger") > 0) {
        if (!code) {
          this.$wxlogin(encodeURIComponent(location.href));
        } else {
          this.payType = "wx";
          this.loadPayOrders(id, code);
        }
      } else if (ua.indexOf("alipayclient") > 0) {
        this.payType = "ali";
        window.ap.getAuthCode(
          {
            appId: this.aliAppId,
            scopes: ["auth_base"],
            showErrorTip: false,
          },
          (res) => {
            this.loadPayOrders(id, res.authCode);
          }
        );
      } else {
        this.$toast({
          duration: 5000,
          message: "为保证信息安全\n请使用微信或支付宝扫码",
        });
      }
    } else {
      this.$toast({
        type: "fail",
        duration: 0,
        message: "账单二维码信息异常，请与收费员核实",
      });
    }
  },
  methods: {
    loadPayOrders(id, code) {
      this.$http.post(
        "/park-service-order/userOrder/selectParkingOrderPayList",
        {
          id,
          code,
          payType: this.payType,
        },
        (res) => {
          if (res.list.length > 0) {
            this.list = res.list;
          } else {
            this.$toast({
              duration: 0,
              message: "未查询到此项支付记录对应的订单信息\n请核实支付信息",
            });
          }
        }
      );
    },
    imagePreview(it) {
      let { inPic, outPic } = it,
        images = [];
      if (inPic) images.push(inPic);
      if (outPic) images.push(inPoutPicic);
      ImagePreview({
        images: images,
        closeable: true,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.history-content {
  height: calc(100% - 1.16rem);
  overflow-y: auto;
  .item {
    width: 6.4rem;
    padding: 0.25rem;
    background-color: #fff;
    border-radius: 12px;
    margin: 0.22rem auto 0 auto;
    p {
      margin: 0.25rem 0;
      font-size: 0.28rem;
    }
    .plate {
      font-size: 0.32rem;
      font-weight: bold;
      span {
        font-weight: normal;
        font-size: 0.28rem;
        margin-right: 5px;
        border-radius: 5px;
        padding: 1px 5px;
      }
      .isNow1 {
        background-color: #fa8500;
        color: #fff;
      }
      .isNow0 {
        background-color: #999;
        color: #fff;
      }
    }
    .location {
      img {
        width: 0.24rem;
        height: 0.24rem;
        vertical-align: middle;
        margin-right: 0.05rem;
      }
    }
    .time {
      color: #8e8e8e;
      padding-left: 0.3rem;
      position: relative;
      &::before {
        position: absolute;
        z-index: 1;
        left: 0.05rem;
        top: 0.14rem;
        content: "";
        display: block;
        width: 0.12rem;
        height: 0.12rem;
        border-radius: 50%;
        background-color: #03c891;
      }
    }
    .blue {
      &::before {
        background-color: #1ca6ff;
      }
    }
    .red {
      &::before {
        background-color: #ec2e6b;
      }
    }
    .line {
      width: 100%;
      height: 1px;
      background-color: #f0f3f0;
      margin: 0.28rem auto;
    }
    .price {
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        margin: 0;
        &:first-child {
          color: #1ca6ff;
          font-size: 0.36rem;
        }
        &:last-child {
          background-color: #1ca6ff;
          color: #fff;
          padding: 0.12rem 0.22rem;
          border-radius: 50px;
        }
      }
    }
  }
}
</style>